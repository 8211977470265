import topic from '../topic';
import { getNoCatch, postNoCatch } from '@api/request';
export const quotationAPI = {
  getQuotations: params => getNoCatch(`${topic.quotationTopic}/getQuotationOther`, params),
  getQuotationById: params => getNoCatch(`${topic.quotationTopic}/getQuotationOtherByid`, params),
  editQuotation: params => postNoCatch(`${topic.quotationTopic}/eidtQuotationOther`, params),
  copyQuotationOtherByid: params => getNoCatch(`${topic.quotationTopic}/copyQuotationOtherByid`, params),
  addQuotationOther: params => postNoCatch(`${topic.quotationTopic}/addQuotationOther`, params),
  deleteQuotationByIds: params => getNoCatch(`${topic.quotationTopic}/deleteQuotationOther`, params),
  getToDyjRqtaList: params => getNoCatch(`${topic.openTopic}/dyj/api/getToDyjRqtaList`, params),
  exportQuotationOther: params => getNoCatch(`${topic.quotationTopic}/exportQuotationOther`, params)
};
