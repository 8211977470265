import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';
import { keep2Decimal } from '@assets/js/regExUtil';

export const tableProperties = [
  store.state.selection,
  store.state.index,
  { label: '报价单据号', prop: 'quotation_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '公司抬头', prop: 'cptt_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: false, sortable: false, widthAuto: true },
  { label: '录入人', prop: 'stff_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '录入部门', prop: 'dept_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户全称', prop: 'cust_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '价格条款', prop: 'price_terms', itemType: 'input', input: false, sortable: true, widthAuto: true },
  {
    label: '录入时间',
    prop: 'create_time',
    itemType: 'date',
    input: true,
    sortable: false,
    widthAuto: true,
    valueFormat: 'timestamp',
    format: 'yyyy-MM-dd',
    formatter: val => getDateNoTime(val, false)
  },
  {
    prop: 'status',
    label: '单据状态',
    itemType: 'select',
    widthAuto: true,
    options: [
      { value: 0, label: '草拟' },
      { value: 1, label: '在批' },
      { value: 2, label: '生效' }
    ]
  }
];

export const quotationMainFormProperties = [
  {
    label: '客户全称',
    prop: 'cust_id',
    itemType: 'select',
    options: [],
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '价格条款',
    prop: 'price_terms',
    itemType: 'select',
    options: [],
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: false
  },
  {
    label: '公司抬头',
    prop: 'cptt_id',
    itemType: 'select',
    options: [],
    model: '',
    size: 'small',
    rules: { required: true, message: ' ' },
    subWidth: 80,
    show: true,
    disabled: false
  }
];

export const quotationEditSubTableProperties = [
  {
    label: '我司货号',
    prop: 'prod_no',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180
  },
  {
    label: '产品图片',
    prop: 'imge_url',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 220,
    subItem: {
      required: false,
      disabled: false,
      type: 'uploadImage',
      image: row => row.imge_url,
      thumbnail: row => row.imge_url
    }
  },
  {
    label: '起运港',
    prop: 'pord_departure',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'select',
      options: []
    }
  },
  {
    label: '产品尺寸',
    prop: 'prod_size',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input'
    }
  },
  {
    label: '尺寸单位',
    prop: 'size_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: true,
      disabled: false,
      type: 'select',
      options: []
    }
  },
  {
    label: '包装方式',
    prop: 'wrap_manner',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input'
    }
  },
  {
    label: '箱规(长*高*宽)cm',
    prop: 'boxGauge',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 240,
    subItem: {
      required: false,
      disabled: false
    }
  },
  { label: '装箱数', prop: 'box_num', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  {
    label: '数量单位',
    prop: 'prod_unit',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: true,
      disabled: false,
      type: 'select',
      options: []
    }
  },
  {
    label: '起订量',
    prop: 'quotation_moq',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input'
    }
  },
  { label: '样品核价(￥)', prop: 'sample_price', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  {
    label: '辅料',
    prop: 'quotation_accessories',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 5,
      input: (val, row) => (row.quotation_accessories = keep2Decimal(val))
    }
  },
  {
    label: '运费',
    prop: 'quotation_freight',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 7,
      input: (val, row) => (row.quotation_freight = keep2Decimal(val))
    }
  },
  {
    label: '其他费用',
    prop: 'other_fees',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 9,
      input: (val, row) => (row.other_fees = keep2Decimal(val))
    }
  },
  {
    label: '换汇',
    prop: 'currency_exchange',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 5,
      input: (val, row) => (row.currency_exchange = keep2Decimal(val))
    }
  },
  {
    label: '费率(%)',
    prop: 'quotation_rates',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 5,
      input: (val, row) => (row.quotation_rates = keep2Decimal(val))
    }
  },
  {
    label: '退税率',
    prop: 'quotation_taxrate',
    itemType: 'input',
    input: false,
    sortable: false,
    labelWidth: 180,
    subItem: {
      required: false,
      disabled: false,
      type: 'input',
      maxLength: 5,
      input: (val, row) => (row.quotation_taxrate = keep2Decimal(val))
    }
  },
  { label: '美金报价', prop: 'quotation_usd', itemType: 'input', input: false, sortable: false, labelWidth: 180 },
  { label: '备注', prop: 'remark', itemType: 'input', input: false, sortable: false, labelWidth: 180, subItem: { type: 'popoverInput' } }
];

export const SPFTableProperties = [
  store.state.selection,
  store.state.index,
  { label: '委托打样单号', prop: 'requ_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '我司货号', prop: 'smpl_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '样品名称', prop: 'smpl_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '含税价格', prop: 'quta_total', itemType: 'input', input: false, sortable: false, widthAuto: true }
];
