<template>
  <el-row>
    <el-form ref="form" :model="formModel" :label-width="labelWidth" label-position="right" size="small" :disabled="isShow">
      <el-row>
        <el-row class="btn-group">
          <slot :name="`header-btn-group-${name}`" />
        </el-row>
        <el-row>
          <el-col :span="totalWidth">
            <div class="row-item" v-for="(formItem, index) in formModel.formList" :key="`formList${index}`">
              <el-col :span="formItem.split ? formItem.split : split" class="vg_mb_8" v-if="formItem.show">
                <el-form-item :rules="formItem.rules" :label="formItem.label" :prop="`formList.${index}.model`" :style="formItemStyle">
                  <span
                    class="flexHV"
                    v-if="formItem.itemType === 'image'"
                    :style="`height:${formItem.itemHeight ? formItem.itemHeight : 100}px;width:${formItem.itemWidth ? formItem.itemWidth : 100}px;`"
                  >
                    <el-upload
                      class="upLoad"
                      :disabled="formItem.disabled"
                      drag
                      :action="formItem.uploadUrl ? formItem.uploadUrl : ''"
                      :data="{
                        file: /\.(png|jpg|gif|jpeg)$/,
                        cut: true,
                        keep: false,
                        x: 800,
                        y: 800
                      }"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload"
                      ref="uploadMultiple"
                    >
                      <div style="height: 100%; width: 100%" class="flexHV">
                        <el-image
                          v-if="formItem.model"
                          :z-index="99999"
                          :initial-index="99999"
                          :preview-src-list="[formItem.imageCustom ? formItem.model : helper.picUrl(formItem.model, 'l')]"
                          :src="formItem.imageCustom ? formItem.model : helper.picUrl(formItem.model, 'm')"
                        />
                        <i v-else class="el-icon-upload" style="margin: 0"></i>
                      </div>
                    </el-upload>
                  </span>
                  <el-switch
                    v-if="formItem.itemType === 'switch'"
                    v-model="formItem.model"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    @change="typeof formItem.change === 'function' ? formItem.change($event) : () => {}"
                  >
                  </el-switch>
                  <div v-if="formItem.itemType === 'input'">
                    <div v-if="computedData && computedData[formItem.prop]" style="display: flex; height: 100%">
                      <el-input :class="formItem.inputAppend ? 'montage' : ''" :value="computedData[formItem.prop]" disabled placeholder="自动计算" />
                      <div v-if="formItem.appendClick" class="appendClass el-input--small">
                        <el-link :disabled="isShow" :underline="false" class="vg_cursor" type="primary" @click="formItem.appendClick">
                          {{ formItem.inputAppend }}
                        </el-link>
                      </div>
                      <div v-else-if="formItem.inputAppend" class="appendClass el-input--small">{{ formItem.inputAppend }}</div>
                    </div>
                    <div v-else style="display: flex; height: 100%">
                      <el-input
                        :class="formItem.inputAppend ? 'montage' : ''"
                        v-bind="$attrs"
                        v-on="$listeners"
                        :type="formItem.type"
                        v-model="formItem.model"
                        :maxlength="formItem.maxlength"
                        :minlength="formItem.minlength"
                        :placeholder="formItem.placeholder ? formItem.placeholder : '请输入'"
                        :disabled="formItem.disabled"
                        :auto-complete="formItem.autoComplete"
                        :max="formItem.max"
                        :min="formItem.min"
                        :step="formItem.step"
                        :name="formItem.name"
                        :resize="formItem.resize"
                        :autofocus="formItem.autofocus"
                        :form="formItem.form"
                        :rows="formItem.rows"
                        :autosize="formItem.autosize"
                        :size="formItem.size"
                        :readonly="formItem.readonly"
                        show-word-limit
                        @blur="typeof formItem.blur === 'function' ? formItem.blur($event) : () => {}"
                        @focus="typeof formItem.focus === 'function' ? formItem.focus($event) : () => {}"
                        @change="typeof formItem.change === 'function' ? formItem.change($event) : () => {}"
                        @input="typeof formItem.input === 'function' ? formItem.input(formModel.formList, index, $event) : () => {}"
                      >
                      </el-input>
                      <div class="appendClass el-input--small" v-if="formItem.appendClick">
                        <el-link :underline="false" type="primary" @click="formItem.appendClick" class="vg_cursor" :disabled="isShow">
                          {{ formItem.inputAppend }}
                        </el-link>
                      </div>
                      <div class="appendClass el-input--small" v-else-if="formItem.inputAppend">{{ formItem.inputAppend }}</div>
                    </div>
                  </div>
                  <el-autocomplete
                    v-if="formItem.itemType === 'autocomplete'"
                    :placeholader="formItem.placeholder"
                    :disabled="formItem.disabled"
                    v-model.trim="formItem.model"
                    :custom-item="formItem.customItem"
                    :fetch-suggestions="formItem.fetchSuggestions"
                    :popper-class="formItem.popperClass"
                    :trigger-on-focus="formItem.triggerOnFocus"
                    @select="typeof formItem.select === 'function' ? formItem.select($event) : () => {}"
                  ></el-autocomplete>
                  <el-date-picker
                    v-if="formItem.itemType === 'date'"
                    v-model="formItem.model"
                    :disabled="formItem.disabled"
                    type="datetime"
                    :placeholder="formItem.label"
                    :format="formItem.formatTime"
                    value-format="timestamp"
                    @blur="typeof formItem.blur === 'function' ? formItem.blur($event) : () => {}"
                  ></el-date-picker>
                  <el-date-picker
                    v-if="formItem.itemType === 'dateNoTime'"
                    v-model="formItem.model"
                    :disabled="formItem.disabled"
                    type="date"
                    :placeholder="formItem.label"
                    :format="formItem.formatTime"
                    value-format="timestamp"
                    @blur="typeof formItem.blur === 'function' ? formItem.blur($event) : () => {}"
                  ></el-date-picker>
                  <div v-if="formItem.itemType === 'radio'">
                    <el-radio v-for="(radioItem, index) in formItem.radioOptions" :key="`radio${index}`" v-model="formItem.model" :label="radioItem.label"
                      >{{ radioItem.text }}
                    </el-radio>
                  </div>
                  <el-select
                    v-model="formItem.model"
                    v-if="formItem.itemType === 'select'"
                    :multiple="formItem.multiple"
                    :clearable="formItem.clearable"
                    :size="formItem.size"
                    :multiple-limit="formItem.multipleLimit"
                    :name="formItem.name"
                    :placeholder="'请选择'"
                    :allow-create="formItem.allowCreate"
                    :disabled="formItem.disabled"
                    :filter-method="formItem.filterMethod"
                    :filterable="formItem.filterable"
                    :loading-text="formItem.loadingText"
                    :no-data-text="formItem.noDataText"
                    :popper-class="formItem.popperClass"
                    :no-match-text="formItem.noMatchText"
                    :loading="formItem.loading"
                    :remote="formItem.remote"
                    @change="typeof formItem.change === 'function' ? formItem.change($event) : () => {}"
                  >
                    <el-option
                      v-for="(item, index) in formItem.options"
                      :key="`select${item.value}${index}`"
                      :label="item.label"
                      :value="item.value"
                      :disabled="item.disabled"
                      :style="selectOptionStyle"
                    ></el-option>
                  </el-select>
                  <span v-else-if="formItem.itemType === 'no'">{{ formItem.formatter ? formItem.formatter(formItem.model) : formItem.model }}</span>
                </el-form-item>
              </el-col>
            </div>
          </el-col>
        </el-row>
      </el-row>
    </el-form>
  </el-row>
</template>
<script>
let vm = {};
//  import * as Model from './model'
import draggable from 'vuedraggable';

export default {
  name: 'Form',
  components: {
    draggable
  },
  data() {
    this.turnData(this.formList);
    return {
      vm: vm,
      refForm: {},
      formModel: {
        formList: this.formList
      }
    };
  },
  props: {
    dynamicForm: { type: Object, default: () => {} },
    computedData: { type: Object, default: () => {} },
    formList: { type: Array, default: () => [] },
    isShow: { type: Boolean, default: false },
    name: String,
    totalWidth: { type: Number, default: 24 },
    split: { type: Number, default: 8 },
    formItemStyle: String,
    labelWidth: { type: String, default: '130px' },
    selectOptionStyle: String
  },
  watch: {
    formList(val) {
      this.formModel.formList = val;
      this.turnData(this.formModel.formList);
    }
  },
  mounted() {
    this.setAuth();
    this.refForm = this.$refs['form'];
  },
  methods: {
    // 通过一个prop字段设置另一个属性 prop tarPro要设置的目标属性值
    setProperByPro(prop, tarPro) {
      if (typeof tarPro !== 'object') {
        return;
      }
      for (let i = 0; i < this.formList.length; i++) {
        if (this.formList[i].prop === prop) {
          for (let key in tarPro) {
            this.formList[i][key] = tarPro[key];
          }
        }
      }
    },
    // 通过prop 获取表单字段对象
    getPropObjByPro(prop) {
      for (let i = 0; i < this.formList.length; i++) {
        if (this.formList[i].prop === prop) {
          return this.formList[i];
        }
      }
    },
    // 设置权限控制
    setAuth() {
      this.formList.map(item => {
        if (typeof item.dataAuth === 'function') {
          item.dataAuth();
        }
      });
    },
    // 点击确认按钮
    clickSubmit() {
      this.$refs['form'].validate(val => {
        if (val) {
          this.$emit('clickSubmit', this.getFormModel());
        }
      });
    },
    // 获取form model
    getFormModel() {
      let workObj = {};
      for (let i = 0; i < this.$refs['form'].model.formList.length; i++) {
        let obj = this.$refs['form'].model.formList[i];
        workObj[obj.prop] = obj.model;
      }
      return workObj;
    },
    // 获取form model // 必要index字段
    getSpecialFormModel() {
      let formList = this.$refs['form'].model.formList;
      let map = formList.map(({ index }) => index);
      let max = Math.max(...map);
      let arr = [];
      if (max === 0) {
        let workObj = {};
        formList.forEach(item => (workObj[item.prop] = item.itemType === 'date' ? new Date(item.model).getTime() : item.model));
        arr.push(workObj);
      } else {
        for (let i = 0; i <= max; i++) {
          let workObj = {};
          let filter = formList.filter(({ index }) => index === i);
          filter.forEach(item => {
            workObj[item.prop] = item.itemType === 'date' ? new Date(item.model).getTime() : item.model;
          });
          arr.push(workObj);
        }
      }
      return { list: arr };
    },
    // 重置表单
    reset() {
      this.$refs['form'].resetFields();
    },
    // 点击取消按钮
    clickCancel() {
      this.$emit('clickCancel', this.$refs['form']);
    },
    // 当一个表单域从enable 变为disabled时 enabled错误信息不显示
    hiddenMsg() {
      for (let i = 0; i < this.formList.length; i++) {
        if (this.formList[i].disabled) {
          this.formList[i].hiddenMsg = true;
        } else {
          this.formList[i].hiddenMsg = false;
        }
      }
    },
    selectChange(fun, val) {
      if (typeof fun === 'function') {
        fun(val);
      }
      this.setAuth();
      this.hiddenMsg();
    },
    turnData(formList) {
      for (let i = 0; i < formList.length; i++) {
        let item = formList[i];
        if (item.show === undefined) {
          item.show = true;
        }
        if (item.disabled === undefined) {
          item.disabled = false;
        }
      }
    },
    handleAvatarSuccess(res) {
      console.log('handleAvatarSuccess', res);
    },
    beforeAvatarUpload(res) {
      console.log('beforeAvatarUpload', res);
      if (!res.size / 1024 / 1024 < 10) {
        this.$message.error('图片大小不得超过 10MB!');
      }
    }
  }
};
</script>
<style scoped>
.flexHV {
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/.el-upload-dragger {
  height: 100%;
  width: 100%;
}
/deep/.el-upload {
  height: 100%;
  width: 100%;
}

.upLoad {
  height: 100%;
  width: 100%;
}

.appendClass {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f7fa;
  border-radius: 0 4px 4px 0;
  border-top: 1px solid #dcdfe6;
  border-right: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-sizing: border-box;
  -webkit-appearance: none;
  height: 32px;
}
/deep/ .el-form-item.is-error .el-input__inner {
  border-color: red;
}
</style>
<style scoped lang="scss">
.montage {
  ::v-deep.el-input__inner {
    border-radius: 4px 0 0 4px !important;
  }
}
</style>
