<template>
  <div class="vg_wrapper">
    <EditHeader
      :isShow="isShow"
      :btn="btn"
      :special="true"
      :strForm="{ status: formStatus }"
      @openEdit="openEdit"
      @closeEdit="closeEdit"
      @submit="submit"
      @refresh="initData"
    >
      <div slot="functionButton" class="vg_ml_8">
        <el-button :disabled="!isShow" class="vg_mb_8" type="warning" size="small" @click="exportQuotationOther(0)"> 打印 </el-button>
        <el-button :disabled="!isShow" class="vg_mb_8" type="success" size="small" @click="exportQuotationOther(1)"> 导出Excel </el-button>
      </div>
      <div slot="otherButton">
        <div class="flexHV vg_mr_8">
          <span>单据号：</span>
          <el-input disabled size="small" :value="quotation_no" style="width: 160px"></el-input>
        </div>
      </div>
      <div slot="specialStatus" style="display: flex">
        <div class="flexHV">
          <span>状态：</span>
          <el-tag :type="helper.getStatusName(formStatus).type">{{ helper.getStatusName(formStatus).name }}</el-tag>
        </div>
      </div>
    </EditHeader>
    <DynamicForm
      v-bind="$attrs"
      :total-width="mainForm.totalWidth"
      :split="mainForm.split"
      :form-list="mainForm.formProperties"
      ref="dynamicForm"
      class="dynamicForm"
      :is-show="isShow"
    />
    <div>
      <el-button v-if="formStatus === 0" :disabled="isShow" class="vg_mb_8" size="small" type="primary" @click="SPFDialogVisible = true"> 导入 </el-button>
      <el-button :disabled="isShow" class="vg_mb_8" type="danger" size="small" @click="delRow()">删除</el-button>
    </div>
    <DynamicUTable
      ref="dynamicUTable"
      :tableData="subTableData"
      :columns="quotationEditSubTableProperties"
      :need-pagination="false"
      :is-show="isShow"
      @selection-change="val => (subSelections = val)"
      :row-click="(row, column, event) => (currentRow = row)"
      :computed-data="{ quotation_usd: quotation_usd }"
      :rowStyle="
        ({ row, rowIndex }) => {
          row.index = rowIndex;
          return { height: '80px' };
        }
      "
    >
      <template v-slot:default-boxGauge="scope"> {{ scope.row.boxgauge_length }}*{{ scope.row.boxgauge_width }}*{{ scope.row.boxgauge_height }} </template>
      <template v-slot:boxGauge="scope">
        <div style="display: flex">
          <el-input size="small" v-model="scope.row.boxgauge_length" @input="val => (scope.row.boxgauge_length = keep2Decimal(val))" />*
          <el-input size="small" v-model="scope.row.boxgauge_width" @input="val => (scope.row.boxgauge_width = keep2Decimal(val))" />*
          <el-input size="small" v-model="scope.row.boxgauge_height" @input="val => (scope.row.boxgauge_height = keep2Decimal(val))" />
        </div>
      </template>
    </DynamicUTable>
    <el-dialog title="导入样品核价单" :visible.sync="SPFDialogVisible" :top="0" width="70%" @opened="getSPFTableData">
      <div class="vg_mb_8">
        <el-button type="info" size="small" icon="el-icon-refresh-right" @click="SPFDialogRefresh()"> 刷新</el-button>
      </div>
      <search-table
        ref="SPFTable"
        :data="SPFTableData"
        :tableRowKey="'SPFDialogInfo'"
        :columns="SPFTableProperties"
        v-loading="SPFLoadFlag"
        :need-fixed-height="true"
        :total-page="SPFTotalPage"
        @row-dblclick="importSPFConfirm"
        @getTableData="getSPFTableData"
      >
      </search-table>
    </el-dialog>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EditHeader from '@/views/component/editHeaderLine.vue';
import inputUser from '@/views/component/inputUser';
import DynamicForm from '@/components/DynamicFormV2.vue';
import { cloneDeep } from 'lodash';
import { quotationEditSubTableProperties, quotationMainFormProperties, SPFTableProperties } from '@/views/DevelopManagement/QuotationManage/quotation';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { quotationAPI } from '@api/modules/quotation';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import { deleteTableRow } from '@assets/js/arrayUtils';
import { changeStaffForm, getCptt, getCustomerInfo, getPort, getPriceTerms, getUnit } from '@api/public';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { keep2Decimal } from '@assets/js/regExUtil';
import { BigNumber } from 'bignumber.js';
import { get } from '@api/request';
import { tranAPI } from '@api/modules/tran';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'quotationEditMain',
  components: {
    SearchTable,
    DynamicUTable,
    DynamicForm,
    EditHeader,
    inputUser
  },
  data() {
    return {
      isShow: true,
      btn: {},
      stffForm: {},
      mainForm: {
        split: 8,
        totalWidth: 24,
        formProperties: cloneDeep(quotationMainFormProperties)
      },
      subTableData: [],
      subSelections: [],
      subTableMap: new Map(),
      quotationEditSubTableProperties: cloneDeep(quotationEditSubTableProperties),
      formStatus: 0,
      orderStatus: 0,
      mesaStatus: 0,
      quotation_no: '',
      quotation_id: '',
      SPFDialogVisible: false,
      SPFTableData: [],
      SPFTableProperties: cloneDeep(SPFTableProperties),
      SPFLoadFlag: false,
      SPFTotalPage: 0,
      currentRow: {}
    };
  },
  computed: {
    quotation_usd() {
      let { sample_price, quotation_taxrate, quotation_accessories, quotation_freight, other_fees, currency_exchange, quotation_rates } = this.currentRow;
      let preconditions = new BigNumber(sample_price).div(quotation_taxrate).plus(quotation_accessories).plus(quotation_freight).plus(other_fees);
      let postConditions = new BigNumber(currency_exchange).times(quotation_rates).div(100);
      let temp = preconditions.div(postConditions);
      if (!temp.isFinite()) {
        this.currentRow.quotation_usd = 0;
        return '自动计算';
      }
      this.currentRow.quotation_usd = temp.toFixed(4);
      return temp.toFixed(4);
    }
  },
  async created() {},
  async mounted() {
    await this.initData();
    await this.changeProperties();
  },
  methods: {
    keep2Decimal,
    async initData() {
      this.getQuotationData();
    },
    async changeProperties() {
      this.mainForm.formProperties[0].options = await getCustomerInfo({ status: 2, type: 'name' });
      this.mainForm.formProperties[1].options = await getPriceTerms();
      this.mainForm.formProperties[2].options = await getCptt();

      this.quotationEditSubTableProperties.find(({ prop }) => prop === 'pord_departure').subItem.options = await getPort('select');
      let unit = await getUnit('select');
      this.quotationEditSubTableProperties.find(({ prop }) => prop === 'size_unit').subItem.options = unit;
      this.quotationEditSubTableProperties.find(({ prop }) => prop === 'prod_unit').subItem.options = unit;
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    getQuotationData() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      quotationAPI.getQuotationById({ quotation_id: props.form_id }).then(({ data }) => {
        this.btn = data.btn;
        this.quotation_no = data.form.quotation_no;
        this.quotation_id = data.form.quotation_id;
        this.formStatus = data.form.status;
        changeStaffForm(this.stffForm, data.form);
        this.mainForm.formProperties.forEach(item => (item.model = data.form[item.prop]));
        this.subTableData = data.form.quotationDetailList;
      });
    },
    submit() {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable = await this.$refs.dynamicUTable.fullValidateMsg();
          this.$refs.dynamicForm.$refs.form.validate((valid, object) => {
            if (valid && !dynamicUTable) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (dynamicUTable) tempStr += `子表-${dynamicUTable}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    saveInfo() {
      quotationAPI.editQuotation(this.getSaveData()).then(({ data }) => {
        this.$message.success('保存成功!');
        this.initData();
        this.isShow = true;
        this.$emit('isShow', this.isShow);
      });
    },
    getSaveData() {
      let formModel = this.$refs.dynamicForm.getFormModel();
      formModel.quotation_id = this.quotation_id;
      formModel.quotation_no = this.quotation_no;
      formModel.status = this.formStatus;
      formModel.quotationDetailList = this.subTableData.concat(Array.from(this.subTableMap.values()));
      return formModel;
    },
    delRow() {
      let { tableData, map } = deleteTableRow('quotation_detail_id', this.subTableData, this.subTableMap, this.subSelections);
      this.subTableData = tableData;
      this.subTableMap = map;
    },
    async getSPFTableData() {
      this.SPFLoadFlag = true;
      await quotationAPI.getToDyjRqtaList(this.$refs.SPFTable.searchForm).then(({ data }) => {
        this.SPFTableData = data.list;
        this.SPFTotalPage = data.total;
        this.SPFLoadFlag = false;
      });
    },
    importSPFConfirm(row) {
      let obj = cloneDeep(this.$refs.dynamicUTable.originalObject);
      obj.boxgauge_length = row.smpl_box_l;
      obj.boxgauge_width = row.smpl_box_w;
      obj.boxgauge_height = row.smpl_box_h;
      obj.prod_size = row.smpl_spec;
      obj.prod_no = row.smpl_no;
      obj.sample_price = row.quta_total;
      obj.box_num = row.smpl_qpc;
      obj.quotation_taxrate = 1.13;
      obj.imge_url = row.imge_url;
      this.subTableData.push(obj);
      this.SPFDialogVisible = false;
    },
    SPFDialogRefresh() {
      this.$refs.SPFTable.resetFields();
      this.getSPFTableData();
    },
    exportQuotationOther(type) {
      this.$confirm('确定打印?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          quotationAPI.exportQuotationOther({ quotation_id: this.quotation_id, type: type }).then(({ data }) => {
            window.open(`/${process.env.NODE_ENV === 'production' ? 'erpfile' : 'file'}${data.pdf_url}.${type === 0 ? 'pdf' : 'xls'}`);
          });
        })
        .catch(err => console.error(err));
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .elx-table .elx-body--column.col--ellipsis > .elx-cell {
  max-height: 80px;
}
</style>
