<template>
  <div class="vg_button_group" style="display: flex">
    <span v-if="isShow === true">
      <el-button type="success" @click="openEdit" size="small" :disabled="!btn.edit">编辑</el-button>
      <el-button type="primary" @click="$emit('refresh')" size="small">刷新</el-button>
    </span>
    <span v-if="isShow === false">
      <el-button type="primary" @click="submit(strForm)" size="small">保存</el-button>
      <el-button @click="closeEdit" size="small">取消</el-button>
    </span>
    <slot name="functionButton" />
    <div class="flexHV" style="margin-left: auto">
      <slot name="otherButton" />
      <div class="flexHV" v-if="!special">
        <span class="vg_tag_label">状态：</span>
        <el-tag :type="helper.getStatusName(strForm.status).type">{{ helper.getStatusName(strForm.status).name }}</el-tag>
      </div>
      <div class="flexHV" v-else>
        <slot name="specialStatus" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'editHeader',
  props: {
    isShow: Boolean,
    special: Boolean,
    btn: Object,
    strForm: Object
  },
  methods: {
    openEdit() {
      this.$emit('openEdit');
    },
    closeEdit() {
      this.$emit('closeEdit');
    },
    submit(strForm) {
      this.$emit('submit');
    }
  }
};
</script>

<style scoped lang="scss"></style>
