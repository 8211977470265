import topic from '../topic';
import { postNoCatch } from '@api/request';

export const docuAPI = {
  getDocus: topic.docuTopic + '/get_docus',
  getDocusV1: topic.docuTopic + '/get_docus_v1',
  uploadDocuments: topic.docuTopic + '/upload_documents',
  uploadInv: topic.sconTopic + '/uploadInv',
  destroyDocuPterById: topic.docuTopic + '/destroy_docu_pter_by_id',
  upload_video: topic.docuTopic + '/upload_video',
  addDocuPter: topic.docuTopic + '/add_docu_pter',
  editDocuType: topic.docuTopic + '/edit_docu_type',
  destroyDocuPtersByIds: topic.docuTopic + '/destroy_docu_pters_by_ids',
  addDocuPterList: params => postNoCatch(`${topic.docuTopic}/add_docu_pter_list`, params)
};
